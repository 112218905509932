const Main = () =>{
    return(
        <div className="main-box">
            <div className="row">
                <h5>New Arrivals 2022</h5>
                <h1>New perfect <span>plant</span> collections</h1>

                <button className="row-btn"><a href="">Shop Now</a></button>
            </div>
            <div className="row">
                <img src="images/image8.jpg" alt="" className="img1" />
            </div>
        </div>
    )
}

export default Main